const searchFacetSeoText = [
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'guest_capacity',
		filter_slug_text: 'Guest Capacity',
		seo_text: 'Small and Micro Wedding Venues',
		filter_id: '737c71e6-705c-4ebb-8aa2-079442fd018d',
		filter_value: '0-50',
		singular: '0-50',
		plural: '0-50',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'price_range',
		filter_slug_text: 'Price Range',
		seo_text: 'Inexpensive Wedding Venues',
		filter_id: '7dd029e6-1879-4381-81b9-5dc4998fbdec',
		filter_value: '$ ⿿ Inexpensive',
		singular: 'inexpensive',
		plural: 'inexpensive',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'price_range',
		filter_slug_text: 'Price Range',
		seo_text: 'Affordable Wedding Venues',
		filter_id: '82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2',
		filter_value: '$$ ⿿ Affordable',
		singular: 'affordable',
		plural: 'affordable',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'price_range',
		filter_slug_text: 'Price Range',
		seo_text: 'Luxury Wedding Venues',
		filter_id: '3a18c67f-f9ac-4ff9-80b8-61ed88bc4cd1',
		filter_value: '$$$$ ⿿ Luxury',
		singular: 'luxury',
		plural: 'luxury',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Backyard Wedding Venues',
		filter_id: '2e224e03-29d9-4d2a-90af-014b8f452957',
		filter_value: 'Backyard',
		singular: 'backyard',
		plural: 'backyards',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Ballrooms & Banquet Hall Wedding Venues',
		filter_id: 'a6d21a34-ac24-4095-ab44-3a60b4f63c9a',
		filter_value: 'Ballroom',
		singular: 'ballroom',
		plural: 'ballrooms',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Forest and Tree Wedding Venues',
		filter_id: '188441ef-411d-4e38-89e2-df607f464b7f',
		filter_value: 'Trees',
		singular: 'forest',
		plural: 'forests',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Barn and Rustic Wedding Venues',
		filter_id: 'cd0d265d-9d41-4ae0-b61a-c32af3144b17',
		filter_value: 'Barn',
		singular: 'barn',
		plural: 'barns',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Beach and Waterfront Wedding Venues',
		filter_id: '74d033ce-80c0-4c46-bb31-e1964b3bb053',
		filter_value: 'Beach',
		singular: 'beach',
		plural: 'beaches',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Brewery Wedding Venues',
		filter_id: '7eabca94-c7c9-4240-b264-1688ca6e385c',
		filter_value: 'Brewery & Distillery',
		singular: 'brewery',
		plural: 'breweries',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Castle Wedding Venues',
		filter_id: '05726adb-23c3-4c21-87bb-3257a00d0867',
		filter_value: 'Castle',
		singular: 'castle',
		plural: 'castles',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'City Hall Weddings',
		filter_id: '6a7519fe-5c1e-45ee-a772-551643262488',
		filter_value: 'City Hall',
		singular: 'city-hall',
		plural: 'city-halls',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Country Club & Golf Club Wedding Venues',
		filter_id: 'c10e254b-fa99-4331-8bf7-159f505bca08',
		filter_value: 'Country Club',
		singular: 'country-club',
		plural: 'country-clubs',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Cruise & Yacht Wedding Venues',
		filter_id: '784c0069-94c2-48d7-9355-c9b4ed4c664a',
		filter_value: 'Cruise',
		singular: 'cruise',
		plural: 'cruises',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Garden and Greenhouse Wedding Venues',
		filter_id: 'f50e352a-606f-466e-aaba-781b6a5897b4',
		filter_value: 'Garden',
		singular: 'garden',
		plural: 'garden',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Historic and Vintage Wedding Venues',
		filter_id: '7a786ae0-a0cb-497a-b038-2d37902a7da3',
		filter_value: 'Historic Venue',
		singular: 'historic-venues',
		plural: 'historic-venues',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Hotel Wedding Venues',
		filter_id: '0520ba69-bf70-42fe-84a7-7efa6b2721bb',
		filter_value: 'Hotel',
		singular: 'hotel-venue',
		plural: 'hotel-venues',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Library Wedding Venues',
		filter_id: 'c02c388c-0e0e-4ed0-97b4-a577510e4ab7',
		filter_value: 'Library',
		singular: 'library',
		plural: 'libraries',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Loft Wedding Venues',
		filter_id: 'e6802e36-9770-4dc9-a4c7-c2ee3abd5676',
		filter_value: 'Loft',
		singular: 'loft',
		plural: 'lofts',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Estate Wedding Venues',
		filter_id: '50d1c007-d79e-4a54-996e-9383e2815148',
		filter_value: 'Estate and Mansion Wedding Venues',
		singular: 'estate',
		plural: 'estates',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Mountain Wedding Venues',
		filter_id: 'e4ab3111-e906-46a6-a56b-626915a2d0bd',
		filter_value: 'Mountain',
		singular: 'mountain',
		plural: 'mountains',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Museum & Art Gallery Wedding Venues',
		filter_id: '4906f172-05aa-4dc3-9255-7373ad1008cd',
		filter_value: 'Museum',
		singular: 'museum',
		plural: 'museums',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Park Wedding Venues',
		filter_id: '7fc37fe4-3740-4dd1-a018-bac2d2a7c36d',
		filter_value: 'Park',
		singular: 'park',
		plural: 'parks',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Religious Wedding Venues',
		filter_id: '8c6fb1a4-09b7-426e-92a5-2de922460a57',
		filter_value: 'Religious Setting',
		singular: 'religious',
		plural: 'religious',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Restaurant Wedding Venues',
		filter_id: '67614dcd-8c7a-4ea8-aa37-b71aaf821801',
		filter_value: 'Restaurant',
		singular: 'restaurant',
		plural: 'restaurants',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Rooftop Wedding Venues',
		filter_id: '382a2455-b72f-4103-ac9d-290168baedbd',
		filter_value: 'Rooftop',
		singular: 'rooftop',
		plural: 'rooftops',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Tented Wedding Venues',
		filter_id: 'e2668214-6fed-400f-b99b-58a13a35f62a',
		filter_value: 'Tented',
		singular: 'tented',
		plural: 'tented',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'settings',
		filter_slug_text: 'Settings',
		seo_text: 'Vineyard & Winery Wedding Venues',
		filter_id: '1859f1f4-cc3c-4682-b064-3b79d42fd619',
		filter_value: 'Vineyard',
		singular: 'vineyard',
		plural: 'vineyards',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'style',
		filter_slug_text: 'Style',
		seo_text: 'Barn and Farm',
		filter_id: 'cd0d265d-9d41-4ae0-b61a-c32af3144b17',
		filter_value: 'Barn and Farm',
		singular: 'barn',
		plural: 'barns',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'uncovered-outdoor',
		filter_slug_text: 'Outdoor Space',
		seo_text: 'Outdoor Event Space',
		filter_id: 'a08cd584-a729-4c79-bcfc-93170d296b9d',
		filter_value: 'Outdoor Event Space',
		singular: 'uncovered-outdoor',
		plural: 'uncovered-outdoor',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'covered-outdoor',
		filter_slug_text: 'Outdoor Space',
		seo_text: 'Covered Outdoors Space',
		filter_id: '62f601ee-12ab-43d5-bb9d-3dc07103e2ed',
		filter_value: 'Covered Outdoors Space',
		singular: 'covered-outdoor',
		plural: 'covered-outdoor',
	},
	{
		category_code: 'REC',
		category_name: 'Reception Venues',
		category_id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		filter_slug: 'outdoor',
		filter_slug_text: 'Outdoor Wedding Venues',
		seo_text: 'Outdoor Wedding Venues',
		filter_id: [
			'a08cd584-a729-4c79-bcfc-93170d296b9d',
			'62f601ee-12ab-43d5-bb9d-3dc07103e2ed',
		],
		filter_value: 'Outdoor Wedding Venues',
		singular: 'outdoor',
		plural: 'outdoor',
	},
	{
		category_code: 'WPH',
		category_name: 'Wedding Photographers',
		category_id: 'a520ad92-ff3f-4869-a382-92c87a5d809e',
		filter_slug: 'photo-video-styles',
		filter_slug_text: 'Photo & Video Styles',
		seo_text: 'Documentary Wedding Photography',
		filter_id: 'ea1e048f-edcd-4be9-9f8a-88414d8486bc',
		filter_value: 'Documentary',
		singular: 'documentary',
		plural: 'documentary',
	},
	{
		category_code: 'WPH',
		category_name: 'Wedding Photographers',
		category_id: 'a520ad92-ff3f-4869-a382-92c87a5d809e',
		filter_slug: 'starting-price_range',
		filter_slug_text: 'Starting Price Range',
		seo_text: 'Affordable Wedding Photographers',
		filter_id: 'bf895e5e-585b-4b5b-b8c9-e1cc0331dee6',
		filter_value: '$0-$999',
		singular: 'affordable',
		plural: 'affordable',
	},
	{
		category_code: 'WPH',
		category_name: 'Wedding Photographers',
		category_id: 'a520ad92-ff3f-4869-a382-92c87a5d809e',
		filter_slug: 'wedding-photography-videography',
		filter_slug_text: 'Photo & Video',
		seo_text: 'Drone Wedding Photography',
		filter_id: '65eeb677-b2e2-4660-8828-de23e472a3c9',
		filter_value: 'Drone',
		singular: 'drone',
		plural: 'drones',
	},
	{
		category_code: 'WPH',
		category_name: 'Wedding Photographers',
		category_id: 'a520ad92-ff3f-4869-a382-92c87a5d809e',
		filter_slug: 'wedding-photography-videography',
		filter_slug_text: 'Photo & Video',
		seo_text: 'Film Wedding Photography',
		filter_id: '76f9a3ac-0293-4a43-b983-67b4c9531997',
		filter_value: 'Film Photography',
		singular: 'film-photography',
		plural: 'film-photography',
	},
	{
		category_code: 'PLN',
		category_name: 'Wedding Planners',
		category_id: 'cc9fb162-595b-42ce-b0f6-45a5b2b1f907',
		filter_slug: 'wedding-activities',
		filter_slug_text: 'Wedding Activities',
		seo_text: 'Marriage Proposal Planners',
		filter_id: 'de0df316-39fa-4a86-9df2-c69e9afeb738',
		filter_value: 'Getting Engaged',
		singular: 'marriage-proposal-planner',
		plural: 'marriage-proposal-planners',
	},
	{
		category_code: 'PLN',
		category_name: 'Wedding Planners',
		category_id: 'cc9fb162-595b-42ce-b0f6-45a5b2b1f907',
		filter_slug: 'wedding-planning',
		filter_slug_text: 'Planning',
		seo_text: 'Day-of Wedding Coordinators',
		filter_id: 'cd594f97-9566-4496-a5f7-737e745bc657',
		filter_value: 'Day-Of Coordination',
		singular: 'day-of-coordination',
		plural: 'day-of-coordinations',
	},
	{
		category_code: 'PLN',
		category_name: 'Wedding Planners',
		category_id: 'cc9fb162-595b-42ce-b0f6-45a5b2b1f907',
		filter_slug: 'wedding-planning',
		filter_slug_text: 'Planning',
		seo_text: 'Destination Wedding Planners',
		filter_id: '01db3117-df42-441c-bd9e-4c07b5542d9b',
		filter_value: 'Destination Wedding Planning',
		singular: 'destination-wedding-planning',
		plural: 'destination-wedding-planning',
	},
	{
		category_code: 'PLN',
		category_name: 'Wedding Planners',
		category_id: 'cc9fb162-595b-42ce-b0f6-45a5b2b1f907',
		filter_slug: 'wedding-planning',
		filter_slug_text: 'Planning',
		seo_text: 'Honeymoon Planners',
		filter_id: '60e5dc9d-7ce7-4ff9-b25e-d5c2670e1817',
		filter_value: 'Honeymoon Planning',
		singular: 'honeymoon-planning',
		plural: 'honeymoon-planning',
	},
	{
		category_code: 'CAT',
		category_name: 'Caterers',
		category_id: '1dbe9436-1f5a-49e3-844b-31d9d5f5a5d8',
		filter_slug: 'wedding-catering-foods',
		filter_slug_text: 'Food & Catering',
		seo_text: 'Buffet Wedding Catering',
		filter_id: 'dd7fd685-d1ab-4604-9ff4-f27e0bcc41e3',
		filter_value: 'Wedding Buffet',
		singular: 'wedding-buffet',
		plural: 'wedding-buffets',
	},
	{
		category_code: 'CAT',
		category_name: 'Caterers',
		category_id: '1dbe9436-1f5a-49e3-844b-31d9d5f5a5d8',
		filter_slug: 'wedding-catering-foods',
		filter_slug_text: 'Food & Catering',
		seo_text: 'Food Truck Wedding Catering',
		filter_id: 'caf40be6-56c0-49b5-9726-eaede25cf736',
		filter_value: 'Food Trucks',
		singular: 'food-truck',
		plural: 'food-trucks',
	},
	{
		category_code: 'CAT',
		category_name: 'Caterers',
		category_id: '1dbe9436-1f5a-49e3-844b-31d9d5f5a5d8',
		filter_slug: 'dietary-options',
		filter_slug_text: 'Dietary Options',
		seo_text: 'Vegan Wedding Catering',
		filter_id: '8fdce806-2b86-487c-89e3-ff2be742fcb8',
		filter_value: 'Vegan',
		singular: 'vegan',
		plural: 'vegans',
	},
	{
		category_code: 'BEA',
		category_name: 'Beauty',
		category_id: '8aa9a07b-fcd8-46ed-a98f-8f3e12689dd2',
		filter_slug: 'wedding-beauty',
		filter_slug_text: 'Beauty',
		seo_text: 'Wedding Hair and Makeup Trials',
		filter_id: '37dc00f1-9446-40ac-922c-8a0636bd907a',
		filter_value: 'Hair & Makeup Trials Available',
		singular: 'wedding-makeup-trials-available',
		plural: 'wedding-makeup-trials-available',
	},
	{
		category_code: 'BEA',
		category_name: 'Beauty',
		category_id: '8aa9a07b-fcd8-46ed-a98f-8f3e12689dd2',
		filter_slug: 'wedding-beauty',
		filter_slug_text: 'Beauty',
		seo_text: 'Hair Stylists',
		filter_id: 'e5d3ed5c-6a42-4210-b570-d9dfe66033f8',
		filter_value: 'Hair Stylists',
		singular: 'hair-stylist',
		plural: 'hair-stylists',
	},
	{
		category_code: 'BEA',
		category_name: 'Beauty',
		category_id: '8aa9a07b-fcd8-46ed-a98f-8f3e12689dd2',
		filter_slug: 'wedding-beauty',
		filter_slug_text: 'Beauty',
		seo_text: 'Wedding Makeup',
		filter_id: '402955a9-7e41-4bf9-9c48-256e67ef4e5c',
		filter_value: 'Makeup',
		singular: 'wedding-makeup',
		plural: 'wedding-makeup',
	},
	{
		category_code: 'BEA',
		category_name: 'Beauty',
		category_id: '8aa9a07b-fcd8-46ed-a98f-8f3e12689dd2',
		filter_slug: 'wedding-beauty',
		filter_slug_text: 'Beauty',
		seo_text: 'On-Site Hair and Makeup',
		filter_id: '8262407b-f79f-4f86-92c5-8c784cd9a867',
		filter_value: 'On-Site Hair & Makeup',
		singular: 'on-site-wedding-hair-makeup',
		plural: 'on-site-wedding-hair-makeup',
	},
	{
		category_code: 'BEA',
		category_name: 'Beauty',
		category_id: '8aa9a07b-fcd8-46ed-a98f-8f3e12689dd2',
		filter_slug: 'wedding-beauty',
		filter_slug_text: 'Beauty',
		seo_text: 'Makeup Artists',
		filter_id: '14f1de19-3060-420e-a10e-5150b908db1f',
		filter_value: 'makeup-artist',
		singular: 'makeup-artist',
		plural: 'makeup-artists',
	},
	{
		category_code: 'BWP',
		category_name: 'Bridal Salons',
		category_id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		filter_slug: 'dresses',
		filter_slug_text: 'Dresses',
		seo_text: 'Bridesmaid Dresses and Gowns',
		filter_id: '770a7c33-ddc8-4fa6-bae8-0ee8161f4613',
		filter_value: 'Bridesmaid Dresses',
		singular: 'bridesmaid-dress',
		plural: 'bridesmaid-dresses',
	},
	{
		category_code: 'BWP',
		category_name: 'Bridal Salons',
		category_id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		filter_slug: 'dresses',
		filter_slug_text: 'Dresses',
		seo_text: 'Flower Girl Dresses and Gowns',
		filter_id: '2b164c28-be02-4796-95b4-877fd6cdd34d',
		filter_value: 'Flower Girl Dresses',
		singular: 'flower-girl-dress',
		plural: 'flower-girl-dresses',
	},
	{
		category_code: 'BWP',
		category_name: 'Bridal Salons',
		category_id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		filter_slug: 'dresses',
		filter_slug_text: 'Dresses',
		seo_text: 'Mother of the Bride Dresses and Gowns',
		filter_id: '69ec0d32-e1e6-4889-a3b2-c6182ab9523e',
		filter_value: 'Mother of the Bride Dresses',
		singular: 'mother-of-the-bride-dress',
		plural: 'mother-of-the-bride-dresses',
	},
	{
		category_code: 'BWP',
		category_name: 'Bridal Salons',
		category_id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		filter_slug: 'dresses',
		filter_slug_text: 'Dresses',
		seo_text: 'Bridal Shower Dresses and Gowns',
		filter_id: 'e7fb99fe-bb7c-4412-baa0-7692a5991fdb',
		filter_value: 'Shower & Party Dresses',
		singular: 'shower-party-dress',
		plural: 'shower-party-dresses',
	},
	{
		category_code: 'BWP',
		category_name: 'Bridal Salons',
		category_id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		filter_slug: 'dresses',
		filter_slug_text: 'Dresses',
		seo_text: 'Wedding Dresses and Gowns',
		filter_id: '400c6883-b11d-4bab-b951-395ddc0abcf3',
		filter_value: 'Wedding Dresses',
		singular: 'wedding-dress',
		plural: 'wedding-dresses',
	},
	{
		category_code: 'BWP',
		category_name: 'Bridal Salons',
		category_id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		filter_slug: 'suits-and-accessories',
		filter_slug_text: 'Suits & Accessories',
		seo_text: 'Tuxedo and Suit Stores',
		meta_text: 'Buy or Rent Tuxedos & Suits',
		filter_id: 'b699d353-401f-43b9-bfa1-79b5b20d0bd0',
		filter_value: 'Tuxedos & Suits',
		singular: 'wedding-tuxedo',
		plural: 'wedding-tuxedos-suits',
	},
	{
		category_code: 'DJS',
		category_name: 'DJs',
		category_id: '37ffe21a-579f-4100-88c1-ca664f5398ce',
		filter_slug: 'price_range',
		filter_slug_text: 'Price Range',
		seo_text: 'Affordable DJs for Weddings',
		filter_id: '82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2',
		filter_value: '$$ ⿿ Affordable',
		singular: 'affordable',
		plural: 'affordable',
	},
	{
		category_code: 'VID',
		category_name: 'Videographers',
		category_id: '4c1e13e9-d500-4f3f-870e-d919beda43cf',
		filter_slug: 'price_range',
		filter_slug_text: 'Price Range',
		seo_text: 'Affordable Wedding Videographers',
		filter_id: '82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2',
		filter_value: '$$ ⿿ Affordable',
		singular: 'affordable',
		plural: 'affordable',
	},
	{
		category_code: 'VID',
		category_name: 'Videographers',
		category_id: '4c1e13e9-d500-4f3f-870e-d919beda43cf',
		filter_slug: 'price_range',
		filter_slug_text: 'Price Range',
		seo_text: 'Luxury Wedding Videographers',
		filter_id: '3a18c67f-f9ac-4ff9-80b8-61ed88bc4cd1',
		filter_value: '$$$$ ⿿ Luxury',
		singular: 'luxury',
		plural: 'luxury',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'wedding-decorations-accents',
		filter_slug_text: 'Decorations & Accents',
		seo_text: 'Flower Girl Florists',
		filter_id: '773d9159-8a57-4210-9cb0-959274d5a976',
		filter_value: 'Flower Girl Baskets',
		singular: 'flower-girl-basket',
		plural: 'flower-girl-baskets',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'wedding-decorations-accents',
		filter_slug_text: 'Decorations & Accents',
		seo_text: 'Plants Florists',
		filter_id: 'bab0bd31-1de8-4a25-81b9-e000ee532e81',
		filter_value: 'Plants',
		singular: 'plant',
		plural: 'plants',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'wedding-flower-arrangements',
		filter_slug_text: 'Flower Arrangements',
		seo_text: 'Bouquet Florists',
		filter_id: 'b0e90aec-5c7a-4803-a288-60867a37939b',
		filter_value: 'Bouquets',
		singular: 'wedding-bouquet',
		plural: 'wedding-bouquets',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'wedding-flower-arrangements',
		filter_slug_text: 'Flower Arrangements',
		seo_text: 'Wedding Boutonnieres',
		filter_id: 'a5ebf3d4-6ff3-4a9b-b895-f4e625ef2284',
		filter_value: 'Boutonnieres',
		singular: 'wedding-boutonniere',
		plural: 'wedding-boutonnieres',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'wedding-flower-arrangements',
		filter_slug_text: 'Flower Arrangements',
		seo_text: 'Centerpiece Florists',
		filter_id: 'e4e8a8f3-722a-4e50-9391-2d438e935d52',
		filter_value: 'Centerpieces',
		singular: 'wedding-centerpiece',
		plural: 'wedding-centerpieces',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'wedding-flower-arrangements',
		filter_slug_text: 'Flower Arrangements',
		seo_text: 'Wedding Corsages',
		filter_id: '7e832b50-ef36-421d-80b6-9ebc53b8abd4',
		filter_value: 'Corsages',
		singular: 'wedding-corsage',
		plural: 'wedding-corsages',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'wedding-flower-arrangements',
		filter_slug_text: 'Flower Arrangements',
		seo_text: 'Wedding Flower Delivery',
		filter_id: '1e403f64-fa9d-44ac-9ef6-b51f4d816e6d',
		filter_value: 'Flower Delivery & Setup',
		singular: 'wedding-flower-delivery-setup',
		plural: 'wedding-flower-delivery-setup',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'price_range',
		filter_slug_text: 'Price Range',
		seo_text: 'Affordable Florists',
		filter_id: '82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2',
		filter_value: '$$ ⿿ Affordable',
		singular: 'affordable',
		plural: 'affordable',
	},
	{
		category_code: 'FLO',
		category_name: 'Florists',
		category_id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		filter_slug: 'price_range',
		filter_slug_text: 'Price Range',
		seo_text: 'High-end Florists',
		filter_id: '3a18c67f-f9ac-4ff9-80b8-61ed88bc4cd1',
		filter_value: '$$$$ ⿿ Luxury',
		singular: 'luxury',
		plural: 'luxury',
	},
	{
		category_code: 'TRA',
		category_name: 'Tranportation',
		category_id: '8f642158-4f07-4a37-8fba-2ed909d224c4',
		filter_slug: 'transportation',
		filter_slug_text: '',
		seo_text: 'Wedding Shuttle Service',
		filter_id: '4e6fef22-d6f1-4668-b7f7-c86ac5d29f30',
		filter_value: 'Wedding Shuttle Service',
		singular: 'wedding-shuttle-service',
		plural: 'wedding-shuttle-services',
	},
	{
		category_code: 'TRA',
		category_name: 'Tranportation',
		category_id: '8f642158-4f07-4a37-8fba-2ed909d224c4',
		filter_slug: 'transportation',
		filter_slug_text: '',
		seo_text: 'Wedding Trolley',
		filter_id: 'a4f239d9-7331-4e56-8f51-9ca5b7cab00e',
		filter_value: 'Wedding Trolley',
		singular: 'wedding-trolley',
		plural: 'wedding-trolleys',
	},
	{
		category_code: 'TRA',
		category_name: 'Tranportation',
		category_id: '8f642158-4f07-4a37-8fba-2ed909d224c4',
		filter_slug: 'transportation',
		filter_slug_text: '',
		seo_text: 'Wedding Limo',
		filter_id: 'a9edc311-27e7-47b5-b39f-2f3aef13b88b',
		filter_value: 'Wedding Limo',
		singular: 'wedding-limo',
		plural: 'wedding-limos-sedans',
	},
	{
		category_code: 'RNT',
		category_name: 'Rental',
		category_id: '540cb3db-2970-418e-834e-329e6e2f499c',
		filter_slug: 'rentals-equipment',
		filter_slug_text: '',
		seo_text: 'Wedding Chairs',
		filter_id: '98c96945-6686-4e5d-b8ad-8ae4761ef376',
		filter_value: 'Wedding Chairs',
		singular: 'wedding-chair',
		plural: 'wedding-chairs',
	},
	{
		category_code: 'RNT',
		category_name: 'Rental',
		category_id: '540cb3db-2970-418e-834e-329e6e2f499c',
		filter_slug: 'rentals-equipment',
		filter_slug_text: '',
		seo_text: 'Wedding Tables',
		filter_id: 'e7318002-df7a-4344-b633-dd7996d110dd',
		filter_value: 'Wedding Tables',
		singular: 'wedding-table',
		plural: 'wedding-tables',
	},
	{
		category_code: 'WPH',
		category_name: 'Wedding Photographers',
		category_id: 'a520ad92-ff3f-4869-a382-92c87a5d809e',
		filter_slug: 'photo-shoot-types',
		filter_slug_text: 'Photo Shoot Types',
		seo_text: '',
		filter_id: '0bb512a8-86d3-4464-9c30-5b46c6f97381',
		filter_value: 'Boudoir',
		singular: 'boudoir',
		plural: 'boudoir',
	},
	{
		category_code: 'DEC',
		category_name: 'Decor',
		category_id: '2bd88708-6937-4dc2-8c07-18f7787e0278',
		filter_slug: 'wedding-decorations-accents',
		filter_slug_text: 'Decorations & Accents',
		seo_text: '',
		filter_id: '1fd77f61-461b-48ad-b7ed-599f73fd5cdf',
		filter_value: 'Ceremony Accessories',
		singular: 'ceremony-accesory',
		plural: 'ceremony-accessories',
	},
	{
		category_code: 'DEC',
		category_name: 'Decor',
		category_id: '2bd88708-6937-4dc2-8c07-18f7787e0278',
		filter_slug: 'wedding-decorations-accents',
		filter_slug_text: 'Decorations & Accents',
		seo_text: '',
		filter_id: 'd3913877-75f9-45d3-b1a7-194202bc2a41',
		filter_value: 'Wedding Designers',
		singular: 'wedding-design',
		plural: 'wedding-design',
	},
	{
		category_code: 'INV',
		category_name: 'Invitations & Paper Goods',
		category_id: '1c0c8116-66ba-4a66-89b5-24807ed0271f',
		filter_slug: 'wedding-stationary',
		filter_slug_text: 'Invitations & Paper Goods',
		seo_text: '',
		filter_id: '68a42356-2a9d-416a-b187-ab9bd3c40aa8',
		filter_value: 'Calligraphy',
		singular: 'wedding-calligraphy',
		plural: 'wedding-calligraphies',
	},
	{
		category_code: 'BAR',
		category_name: 'Bar Services & Beverages',
		category_id: '0becf958-f2f3-42e0-ab89-89a90128b0ad',
		filter_slug: 'wedding-bar-drinks',
		filter_slug_text: 'Bar & Drinks',
		seo_text: '',
		filter_id: '8ebe1491-e4ae-4c71-b058-43de26798820',
		filter_value: 'Bartenders',
		singular: 'wedding-bartender',
		plural: 'wedding-bartenders',
	},
];

export default searchFacetSeoText;
